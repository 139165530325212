import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: var(--spacingM);
  grid-template-areas:
    "claim"
    "list"
    "miniCard";

  @media screen and (min-width: 1128px) {
    grid-template-columns: 4fr 8fr;
    grid-template-rows: auto;
    grid-template-areas:
      "claim list"
      "miniCard list";
  }
`;

export const Claim = styled.div`
  width: 100%;
  min-height: 160px;
  border-radius: var(--radiusL);
  background-color: var(--gray100);
  color: var(--primary800);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacingM);
  grid-column-start: 1;
  grid-column-end: end;
  grid-area: claim;

  > h3 {
    font-size: var(--textXL);
    font-weight: lighter;
    line-height: var(--lineHeightXL);
    margin: 0px;
    padding: 0px;
  }

  @media screen and (max-width: 1128px) {
    min-height: auto;

    > h3 {
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

export const CheckListWrapper = styled.ul`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  grid-area: list;

  @media screen and (min-width: 1128px) {
    grid-row-start: 1;
    grid-row-end: end;
  }
`;
export const MiniCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacingM);
  grid-area: miniCard;
  justify-items: stretch;
`;

export default ContentWrapper;
