import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import { Section } from "..";
import {
  ContentWrapper,
  Claim,
  MiniCardWrapper,
  CheckListWrapper,
} from "./styles";

const MiniCardItem = dynamic(() =>
  import("../../elements/mini-card-item/mini-card-item")
);
const CheckListItem = dynamic(() =>
  import("../../elements/checklist-item/checklist-item")
);

export const CheckList = ({
  title,
  sectionSubtitle,
  hideLineSeparation,
  checkListItems,
  miniCards,
  claim,
  displaySetting
}) => {
  return (
    <Section
      title={title}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <ContentWrapper>
        <Claim>
          <h3>{renderLineBreaks(claim)}</h3>
        </Claim>
        <MiniCardWrapper>
          {miniCards &&
            miniCards.map((miniCard) => (
              <MiniCardItem key={miniCard.id} {...miniCard} featured={false} />
            ))}
        </MiniCardWrapper>
        <CheckListWrapper>
          {checkListItems &&
            checkListItems.map((listItem, index) => (
              <CheckListItem key={`listItemKey${index}`} {...listItem} />
            ))}
        </CheckListWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default CheckList;
